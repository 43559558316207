<template>
  <div class="or-separator">
    <span class="text">OR</span>
    <span class="liner" />
  </div>
</template>

<script lang="ts">

export default {
  name: 'OrSeparator',
  components: {},
  data() {
    return {};
  },
};
</script>

<style scoped lang="sass">
.or-separator
  display: flex
  align-items: center
  justify-content: center
  font-size: 1rem
  height: 30px
  min-height: 30px
  width: 100%
  margin: 5px 0
  .text
    padding: 5px 30px
    background-color: #fff
    color: #5f7d95
    font-weight: bold
    position: absolute
  .liner
    width: 100%
    height: 1px
    background-color: rgba(0, 0, 0, 0.3)
</style>
