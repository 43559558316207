<template>
  <form class="login-form" @submit.prevent="login">
    <div class="">Username</div>
    <ion-input
      v-model.trim="username"
      class="c-input username-input mt-1"
      placeholder="Username"
      autocapitalize="off"
    />

    <div class="mt-4">Password</div>
    <ion-password-input
      v-model="password"
      class="c-input pw-input mt-1"
      placeholder="Password"
      autocapitalize="off"
      type="password"
    />

    <ion-custom-button :loading="loading" class="login-button mx-auto" type="submit"> Log in </ion-custom-button>
    <input type="submit" hidden />
  </form>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-property-decorator';
import { usernameIsValid } from '@/shared/utils/auth';
import IonPasswordInput from '@/shared/components/ion-password-input.vue';
import IonCustomButton from '@/shared/components/ion-custom-button.vue';
import { toast } from '@/shared/native';
import { authStore } from '@/shared/pinia-store/auth';


@Options({
  name: 'LoginForm',
  components: {
    IonPasswordInput,
    IonCustomButton,
  },
})
export default class LoginForm extends Vue {
  public username = '';
  public password = '';
  public loading = false;
  public showPassword = false;
  public nextState = '/myhome';
  public timer: any;
  public retry = false;

  public async login() {
    this.loading = true;
    const { username, password } = this;

    // No data detection until user interaction
    if (!username && !password && !this.retry) {
      setTimeout(() => this.login(), 5);
      this.retry = true;
    }

    const usernameValid = usernameIsValid(username);

    if (!usernameValid) {
      const message = 'Invalid username';
      await toast.show(message, 'nonative', 'danger');
      this.loading = false;
      return;
    }

    const loginInfo = { username, password };

    try {
      const router = useRouter();
      const { login } = authStore();
      await login(loginInfo);

      const { user } = authStore();

      // if (user.value.is_nsfw || user.value.show_nsfw) {
      //   const alert = await alertController.create({
      //     cssClass: 'char-warning-alert',
      //     header: 'Warning',
      //     message: `You are about to see potentially mature content, by proceeding you confirm you are 18 years of age or older.`,
      //     buttons: [
      //       {
      //         text: 'Continue',
      //         id: 'confirm-button',
      //         role: 'ok',
      //         cssClass: 'mx-auto',
      //       },
      //     ],
      //     backdropDismiss: false,
      //   });
      //   await alert.present();
      // }

      router.push(this.nextState);
    } catch (e) {
      const error: any = e;
      const body = 'Could not sign in';
      const message = error ? error.message || body : body;
      await toast.show(message, 'nonative', 'danger');
    } finally {
      this.loading = false;
      clearInterval(this.timer);
    }
  }

  public mounted() {
    // reactivity missing from auto fill for password
    this.timer = setInterval(() => {
      try {
        const document = useDocument();
        const userNameInputWrapper = document.value?.querySelector('.username-input');
        const userNameInput = userNameInputWrapper?.children[0] as HTMLInputElement;
        const userNameFromInput = userNameInput?.value;
        const passwordInputWrapper = document.value?.querySelector('.password-input');
        const passwordInput = passwordInputWrapper?.children[0] as HTMLInputElement;
        const passwordFromInput = passwordInput?.value;
        this.password = passwordFromInput || this.password;
        this.username = userNameFromInput || this.username;
      } catch {}
    }, 250);
  }
}
</script>

<style lang="sass" scoped>
.login-form
  width: 100%
  .pw-input, .login-button
    margin-top: 16px
  .login-button
    max-width: 150px
</style>
