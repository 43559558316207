<template>
  <div class="login-paragraphs">
    <p class="have-acc text-center">
      <strong>Not a member? <router-link to="/signup"> Sign up </router-link>to join us!</strong>
    </p>
    <p class="have-acc text-center">
      Forgot Password?
      <router-link to="/forget-password"> Reset Password </router-link>
    </p>
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-property-decorator';
import { Options } from 'vue-class-component';

@Options({
  name: 'LoginParagraphs',
  components: {},
})
export default class LoginParagraphs extends Vue {}
</script>

<style lang="sass" scoped>
.login-paragraphs
  text-align: center
.have-acc-agree
  font-size: 16px
</style>
